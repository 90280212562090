import '../assets/scss/styles.scss';

import React from 'react';
import Helmet from 'react-helmet';
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Brev Dic</title>
      </Helmet>

      <Header />

      <main className="d-flex flex-column fill overflow-hidden">
        {children}
      </main>
    </>
  );
};

export default Layout;
