import React from 'react';
import {Button, ModalHeader} from 'reactstrap';

const ModalHeaderWithClose = ({ children, hide }) => {
  return (
    <ModalHeader className="with-close">
      <span className="fill">
        {children}
      </span>
      <span>
        <Button
          color="anchor"
          onClick={hide}
        >
          <i className="fa fa-times" />
        </Button>
      </span>
    </ModalHeader>
  );
};

export default React.memo(ModalHeaderWithClose);
