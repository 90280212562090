import React from 'react';
import {Link} from "gatsby";
import {Container} from "reactstrap";

const Header = () => (
  <nav className="navbar navbar-expand navbar-dark bg-primary">
    <Container>
      <Link className="navbar-brand" to="/">Brev Dic</Link>
    </Container>
  </nav>
);

export default Header;
