export const PartOfSpeech = {
  Noun: 1,
  Pronoun: 2,
  Adjective: 3,
  Determiner: 4,
  Verb: 5,
  Adverb: 6,
  Preposition: 7,
  Conjunction: 8,
  Interjection: 9,
};

export const PartOfSpeechOption = {
  [PartOfSpeech.Noun]: { label: 'Noun', value: PartOfSpeech.Noun },
  [PartOfSpeech.Pronoun]: { label: 'Pronoun', value: PartOfSpeech.Pronoun },
  [PartOfSpeech.Adjective]: { label: 'Adjective', value: PartOfSpeech.Adjective },
  [PartOfSpeech.Determiner]: { label: 'Determiner', value: PartOfSpeech.Determiner },
  [PartOfSpeech.Verb]: { label: 'Verb', value: PartOfSpeech.Verb },
  [PartOfSpeech.Adverb]: { label: 'Adverb', value: PartOfSpeech.Adverb },
  [PartOfSpeech.Preposition]: { label: 'Preposition', value: PartOfSpeech.Preposition },
  [PartOfSpeech.Conjunction]: { label: 'Conjunction', value: PartOfSpeech.Conjunction },
  [PartOfSpeech.Interjection]: { label: 'Interjection', value: PartOfSpeech.Interjection },
};

export const PartOfSpeechOptions = [
  PartOfSpeechOption[PartOfSpeech.Noun],
  PartOfSpeechOption[PartOfSpeech.Pronoun],
  PartOfSpeechOption[PartOfSpeech.Adjective],
  PartOfSpeechOption[PartOfSpeech.Determiner],
  PartOfSpeechOption[PartOfSpeech.Adverb],
  PartOfSpeechOption[PartOfSpeech.Preposition],
  PartOfSpeechOption[PartOfSpeech.Conjunction],
  PartOfSpeechOption[PartOfSpeech.Interjection],
]
