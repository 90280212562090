import React, {useEffect, useState} from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import ModalHeaderWithClose from "./ModalHeaderWithClose";
import {PartOfSpeech, PartOfSpeechOptions} from "../constants/PartOfSpeech";
import {window} from "browser-monads";

const SaveBrevModal = ({ show, brev, onSaveBrev, hide }) => {
  const [workingValues, setWorkingValues] = useState({});

  useEffect(() => {
    if(show) {
      setWorkingValues({
        name: brev.name || '',
        phonetic: brev.phonetic || '',
        definition: brev.definition || '',
        pronunciation: brev.pronunciation || '',
        partOfSpeechId: brev.partOfSpeechId || PartOfSpeech.Noun,
      })
    } else {
      setWorkingValues({});
    }
  }, [show]);

  function getMergedBrev() {
    return {
      ...brev,
      ...workingValues
    };
  }

  function setValues(values) {
    setWorkingValues({
      ...workingValues,
      ...values,
    })
  }

  function saveBrev () {
    (async () => {
      if(brev.brevId) {
        await fetch(`https://brevdic.azurewebsites.net/api/brevs/${brev.brevId}`, {
          method: 'PUT',
          body: JSON.stringify(mergedBrev)
        });

        hide();
        onSaveBrev();

      } else {
        await fetch(`https://brevdic.azurewebsites.net/api/brevs`, {
          method: 'POST',
          body: JSON.stringify(mergedBrev)
        });

        hide();
        onSaveBrev();
      }
    })();
  }

  function deleteBrev () {
    if(!brev.brevId) {
      return;
    }

    if(!window.confirm('Are you sure you want to delete this brev?')) {
      return;
    }

    (async () => {
      await fetch(`https://brevdic.azurewebsites.net/api/brevs/${brev.brevId}`, {
        method: 'DELETE',
      });

      hide();
      onSaveBrev();
    })();
  }

  const mergedBrev = getMergedBrev();

  return (
    <Modal isOpen={show} toggle={hide}>
      <ModalHeaderWithClose hide={hide}>
        {brev && brev.brevId ? 'Update' : 'Add'} Brev
      </ModalHeaderWithClose>
      <ModalBody>
        <Row>
          <Col md={12}>
            <Form>
              <FormGroup>
                <Label>Brev:</Label>
                <Input value={mergedBrev.name} onChange={(e) => setValues({ name: e.target.value })} />
              </FormGroup>

              <FormGroup>
                <Label>Part of Speech:</Label>
                <Input type="select" value={mergedBrev.partOfSpeechId} onChange={(e) => setValues({ partOfSpeechId: parseInt(e.target.value, 10) })}>
                  {PartOfSpeechOptions.map(({label, value}) => {
                    return (
                      <option key={value} value={value}>{label}</option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Phonetic:</Label>
                <Input value={mergedBrev.phonetic} onChange={(e) => setValues({ phonetic: e.target.value })} />
              </FormGroup>

              <FormGroup>
                <Label>Text-to-Speech Pronunciation:</Label>

                <InputGroup>
                  <Input value={mergedBrev.pronunciation} onChange={(e) => setValues({ pronunciation: e.target.value })} />
                  <InputGroupAddon addonType="append">
                    <Button onClick={() => {
                      const msg = new SpeechSynthesisUtterance();
                      msg.text = mergedBrev.pronunciation;
                      window.speechSynthesis.speak(msg);
                    }}>
                      <i className="fa fa-play-circle"/>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label>Definition:</Label>
                <Input type="textarea" value={mergedBrev.definition} onChange={(e) => setValues({ definition: e.target.value })} />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex flex-row fill justify-content-between align-items-center">
          <div>
            {!!(brev && brev.brevId) && (
              <Button color="danger" onClick={deleteBrev}>Delete</Button>
            )}
          </div>
          <div>
            <Button color="primary" onClick={saveBrev}>Save</Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SaveBrevModal;
