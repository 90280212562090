import * as React from "react";
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useEffect, useState} from "react";
import {window} from 'browser-monads';
import sortBy from 'lodash/sortBy';
import Layout from "../components/Layout";
import {Button, Container, Form, Input, Label} from "reactstrap";
import {PartOfSpeechOption} from "../constants/PartOfSpeech";
import SaveBrevModal from "../components/SaveBrevModal";

const IndexPage = () => {
  const voices = window.speechSynthesis.getVoices();

  const [isLoadingBrevs, setIsLoadingBrevs] = useState(false);
  const [selectedBrev, setSelectedBrev] = useState(undefined);
  const [selectedVoice, setSelectedVoice] = useState(undefined);
  const [brevs, setBrevs] = useState([]);

  useEffect(() => {
    loadAllBrevs();
  }, []);

  function loadAllBrevs() {
    (async () => {
      await loadAllBrevsAsync();
    })();
  }

  async function loadAllBrevsAsync() {
    setIsLoadingBrevs(true);
    try {
      const response = await fetch('https://brevdic.azurewebsites.net/api/brevs');
      const brevs = await response.json();
      setBrevs(sortBy(brevs, b => b.name.toLowerCase()));
    } finally {
      setIsLoadingBrevs(false);
    }
  }

  return (
    <Layout>
      <Container className="border-bottom">
        <div className="d-flex flex-row align-items-center justify-content-between py-2">
          <div>
            <Button color="link" onClick={() => setSelectedBrev({ brevId: 0 })}>
              <i className="fa fa-plus-circle"/> Add Brev
            </Button>
          </div>
          <div>
            <Form inline>
              <Label className="mr-2">Voice: </Label>
              <Input style={{ width: 160 }} type="select" value={selectedVoice ? selectedVoice.voiceURI : ''} onChange={(e) => setSelectedVoice(voices.find(v => v.voiceURI === e.target.value))}>
                <option value=""></option>
                {
                  voices.map((voice) => {
                    return (
                      <option key={voice.voiceURI} value={voice.voiceURI}>{voice.name}</option>
                    );
                  })
                }
              </Input>
            </Form>
          </div>
        </div>
      </Container>

      <Container className="d-flex flex-column fill overflow-hidden">
        {isLoadingBrevs && (
          <h2 className="text-center p-2">
            <i className="fa fa-spin fa-refresh"/> Loading Brevs
          </h2>
        )}

        {!isLoadingBrevs && (
          <PerfectScrollbar className="brev-list">
            {brevs.map((brev) => {
              return (
                <React.Fragment key={brev.brevId}>
                  <div className="brev" onDoubleClick={() => setSelectedBrev(brev)}>
                    <div className="fill">
                      <h3 className="name">{brev.name}<span className="phonetic text-muted">({brev.phonetic})</span></h3>
                      <h6 className="part-of-speech">{PartOfSpeechOption[brev.partOfSpeechId].label}</h6>
                      <p className="definition">{brev.definition}</p>
                    </div>

                    <div>
                      <Button color="anchor" onClick={() => {
                        const msg = new SpeechSynthesisUtterance();
                        msg.text = brev.pronunciation;
                        msg.voice = selectedVoice;
                        window.speechSynthesis.speak(msg);
                      }}>
                        <i className="fa fa-3x fa-play-circle"/>
                      </Button>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          </PerfectScrollbar>
        )}
      </Container>

      <SaveBrevModal
        show={!!selectedBrev}
        brev={selectedBrev}
        onSaveBrev={loadAllBrevs}
        hide={() => setSelectedBrev(undefined)} />
    </Layout>
  )
}

export default IndexPage
